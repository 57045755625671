<script setup lang="ts">
/**
 * @description UiCarouselコンポーネントの場合、複数カルーセルを設置するとprevボタンやnextボタンを押下した際、連動して動いてしまう。このコンポーネントはそれに対応するためのもの。
 */
import { computed } from 'vue';
import { register } from 'swiper/element/bundle';
import { Navigation } from 'swiper/modules';

import UiCarouselItem, { UiCarouselItemProps } from '@/components/ui/carousel/UiCarouselItem.vue';

import { ref } from '#imports';

const itemListRef = ref<UiCarouselItemProps[]>([]);

const props = defineProps<{
  items: UiCarouselItemProps[];
  // カルーセル番号
  carouselNum: number;
  // カルーセルの総数
  carouselTotal: number;
}>();

if (props.items.length < 5) {
  // 要素が3以下だとループしないので要素複製して追加する
  // 要素が4だとresize時に表示崩れが発生したので、要素複製して追加する
  itemListRef.value = props.items.concat(props.items);
} else {
  itemListRef.value = props.items;
}

const loopItems = computed(() =>
  itemListRef.value.length === 2 ? [...itemListRef.value, ...itemListRef.value] : itemListRef.value
);
const swiperProps = computed(() => {
  return {
    loop: true,
    speed: 600,
    slidesPerView: 1.1, // 楽スマ(320px)最小想定
    /**
     * 現行はjQueryでレスポンシブに表示を切り替えているが、jQueryの移行ができないためプラグインで再現をしている
     * breakPointを細かく張ることで画面幅を変更した際に、大きくずれることが無いようにしている
     */
    breakpoints: {
      330: { slidesPerView: 1.15 },
      348: { slidesPerView: 1.2 },
      365: { slidesPerView: 1.25 },
      389.7: { slidesPerView: 1.3 },
      419.1: { slidesPerView: 1.4 },
      448.5: { slidesPerView: 1.5 },
      485: { slidesPerView: 1.6 },
      523: { slidesPerView: 1.7 },
      548: { slidesPerView: 1.8 },
      573: { slidesPerView: 1.9 },
      598: { slidesPerView: 2 },
      626.2: { slidesPerView: 2.1 },
      654.4: { slidesPerView: 2.2 },
      682.6: { slidesPerView: 2.3 },
      710.8: { slidesPerView: 2.4 },
      739: { slidesPerView: 2.5 },
      767.2: { slidesPerView: 2.6 },
      795.4: { slidesPerView: 2.7 },
      823.6: { slidesPerView: 2.8 },
      851.8: { slidesPerView: 2.9 },
      880: { slidesPerView: 3 }, // 現行の3 + 横に見切れる表示はエンハンス対応
    },
    centeredSlides: true,
    autoplay: { delay: 3000 },
    grabCursor: true,
    modules: [Navigation],
  };
});
register();
</script>

<template>
  <div class="wrapper">
    <div v-for="j in [...Array(carouselTotal).keys()].map((i) => i + 1)" :key="j">
      <swiper-container
        v-if="carouselNum === j"
        v-bind="swiperProps"
        :navigation="{
          nextEl: `.slide_next-${j}`,
          prevEl: `.slide_prev-${j}`,
        }"
      >
        <UiCarouselItem v-for="(item, i) in loopItems" v-bind="item" :key="i" />
      </swiper-container>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
